<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} coupons</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} coupons that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.COUPONS_CREATE)"
        to="/coupons/new"
        data-test="btn-new-coupon"
        class="btn btn-primary ml-auto">
        New coupon
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-coupons" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Coupon</th>
                  <th class="text-center">Amount</th>
                  <th>Expires at</th>
                  <th class="text-center">Used</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.code)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link v-if="can(uiPermissions.COUPONS_VIEW)" :to="`/coupons/${item.id}`">
                        {{item.code}}
                      </router-link>
                      <span v-else>{{item.code}}</span>
                    </div>
                    <div class="small text-muted">{{truncate(item.description)}}</div>
                  </td>
                  <td v-if="item.type === CouponTypes.Fixed" class="text-center">
                    AED
                    {{numeral(item.amount).format('0,0[.]00')}}
                  </td>
                  <td v-else class="text-center">{{numeral(item.amount / 100).format('%')}}</td>
                  <td>
                    {{item.expiry ? moment(item.expiry).format('dddd, D MMM YYYY') : ''}}
                  </td>
                  <td class="text-center">
                    <i v-if="item.used" class="fe fe-check"></i>
                  </td>
                  <td>
                    {{moment.utc(item.updatedAt).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.COUPONS_VIEW, uiPermissions.COUPONS_DELETE])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div  class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.COUPONS_VIEW)"
                          :to="`/coupons/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                        <div v-if="can(uiPermissions.COUPONS_DELETE)" class="dropdown-divider"></div>
                        <button
                          v-if="can(uiPermissions.COUPONS_DELETE)"
                          type="button"
                          data-test="btn-delete"
                          class="dropdown-item"
                          @click="handleDelete(item)">
                          <i class="dropdown-icon fe fe-trash"></i> Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="9">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {coupons} from '@/services';
import CouponTypes from '@hellochef/shared-js/enums/CouponTypes';

export default {

  components: {

    Pagination,
  },
  mixins: [

    list,
  ],
  data() {
    return {

      CouponTypes,
    };
  },
  methods: {

    fetchData(page) {
      return coupons.getByParameters({

        limit: this.limit,
        page,
        query: this.query,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting a coupon cannot be undone. Are you sure that you want to delete this coupon?')) {
        await coupons.deleteById(item.id);
        this.refresh();
      }
    },
  },
};

</script>
